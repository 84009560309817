import React from 'react';
import { Link } from 'gatsby';
import { Paper, Typography } from '@material-ui/core';
import Layout, { Wrapper } from '../layouts/Layout';
import styled from '../styled';
import { SEO } from '../components/SEO';
import { HidableBranding as Branding } from '../components/Branding';
import { Highlight } from '../components/Highlight';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { MEMBER_COUNT, SUBSCRIBER_COUNT } from '../constants';

const StyledA = styled('a')`
  border-bottom: 2px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
`;

const SharingWidgetWrapper = styled('div')`
  .inner {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  #sl-sharing-screen-container {
    margin: 0 auto;
    padding: ${(p) => p.theme.spacing(4)}px ${(p) => p.theme.spacing(2)}px;
  }

  #sl-sharing-link-container {
    background-color: #2d3238;
    margin-top: ${(p) => p.theme.spacing(4)}px;
    padding: ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    border: 0;
  }
  #sl-sharing-link {
    font-family: Lato;
    margin-right: ${(p) => p.theme.spacing(2)}px;
    padding: ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
  #sl-sharing-link-button {
    font-family: Lato;
    background-color: ${(p) => p.theme.palette.primary.main} !important;
    text-transform: capitalize;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;

    @media (max-width: 768px) {
      margin-top: ${(p) => p.theme.spacing(2)}px;
      padding: ${(p) => p.theme.spacing(2)}px;
    }
  }
  #sl-sharing-social-twitter {
    background: url(/images/rewards/twitter.png);
  }
  #sl-sharing-social-facebook {
    background: url(/images/rewards/facebook.png);
  }
  #sl-sharing-social-email {
    background: url(/images/rewards/email.png);
  }
  #sl-sharing-social-whatsapp {
    background: url(/images/rewards/whatsapp.png);
  }
`;

const SparkloopWrapper = styled('div')`
  a {
    border-bottom: 1px solid;
  }

  #sl-form {
    background-color: #22262b;
    margin-top: ${(p) => p.theme.spacing(4)}px;
    padding: ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;
    border: 0;
  }

  #sl-form-input-email {
    font-family: Lato;
    margin-right: ${(p) => p.theme.spacing(2)}px;
    padding: ${(p) => p.theme.spacing(2)}px;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
  #sl-form-input-button {
    font-family: Lato;
    background-color: ${(p) => p.theme.palette.primary.main} !important;
    text-transform: capitalize;
    border-radius: ${(p) => p.theme.shape.borderRadius}px;

    @media (max-width: 768px) {
      margin-top: ${(p) => p.theme.spacing(2)}px;
      padding: ${(p) => p.theme.spacing(2)}px;
    }
  }
`;

const RewardTable = styled('div')`
  display: grid;
  max-width: 850px;
  margin: ${(p) => p.theme.spacing(14)}px auto;
  grid-template-columns: 40% 60%;
  grid-column-gap: ${(p) => p.theme.spacing(4)}px;
  grid-row-gap: ${(p) => p.theme.spacing(10)}px;

  h2 {
    font-weight: 600;
  }

  @media (max-width: 800px) {
    padding: ${(p) => p.theme.spacing(4)}px;
    grid-row-gap: ${(p) => p.theme.spacing(3)}px;
    grid-template-columns: 1fr;
  }
`;

const RewardImage = styled(Paper)`
  background-color: #22262b;
  padding: ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
  }
`;

const Chip = styled<'div', { variant: 'light' | 'dark' }>('div')`
  display: inline-block;
  background-color: ${(p) => (p.variant === 'light' ? '#1890FF' : '#0050B3')};
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 700;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  border-radius: 4px;
  margin-right: 6px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

const RewardCopy = styled('p')`
  font-size: 17px;
  line-height: 30px;
`;

const RewardItem = ({
  referralCount,
  value,
  image,
  title,
  description
}: {
  referralCount: number;
  value: string;
  image: string;
  title: string;
  description: React.ReactNode;
}) => {
  return (
    <>
      <RewardImage>
        <img src={`/images/rewards/${image}`} alt={title} />
      </RewardImage>
      <div>
        <Chip variant="light">
          {referralCount} {referralCount > 1 ? 'Referrals' : 'Referral'}
        </Chip>
        <Chip variant="dark">{value}</Chip>
        <Typography variant="h5" component="h2" paragraph>
          {title}
        </Typography>
        <RewardCopy>{description}</RewardCopy>
      </div>
    </>
  );
};

const ReferralCountWrapper = styled('div')`
  text-align: left;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  background-color: #1890ff;
  color: #fff;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(1)}px
    ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  max-width: 350px;
  margin: 0 auto;
`;

const ReferralCountWrapperInner = styled('div')`
  color: #000;
  background-color: #fff;
  text-align: center;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.shape.borderRadius}px;

  span {
    display: inline-block;
    font-weight: 600;
    margin: 0 auto;
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Join the Blogging for Devs Referral Program"
        siteUrl="https://bloggingfordevs.com"
        description="Share the Blogging for Devs Email Course and get access to fun and fabulous rewards for referring your friends and followers."
        pathname="referrals/"
        image="/images/rewards/twitter-preview.png"
      />
      <ProductHuntBanner />
      <div
        style={{
          margin: '0 auto'
        }}
      >
        <Wrapper
          style={{
            maxWidth: '700px',
            padding: '16px',
            textAlign: 'center',
            marginBottom: 0
          }}
        >
          <Branding to="/">Blogging for Devs</Branding>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            style={{ marginBottom: '24px' }}
          >
            Referral Program
          </Typography>
          <Typography variant="h6" component="p" color="textSecondary">
            Share the Blogging for Devs Email Course and get access to fun and
            fabulous rewards for referring your friends and followers.
          </Typography>
          <Typography
            variant="body2"
            component="p"
            color="textSecondary"
            style={{ marginTop: '18px' }}
          >
            <Highlight>
              Haven't done the course yet?{' '}
              <Link to="/" style={{ borderBottom: '1px solid' }}>
                Sign up here
              </Link>{' '}
              to try it and become an ambassador.
            </Highlight>
          </Typography>
          <SharingWidgetWrapper>
            <div className="inner" data-ambassador-sharing></div>
          </SharingWidgetWrapper>
          <SparkloopWrapper>
            <div data-ambassador-form></div>
          </SparkloopWrapper>
        </Wrapper>
        <ReferralCountWrapper>
          <div>Your referral count so far</div>
          <ReferralCountWrapperInner>
            <span data-ambassador-referrals>0</span>
          </ReferralCountWrapperInner>
        </ReferralCountWrapper>
        <Typography
          variant="h6"
          component="p"
          color="textSecondary"
          style={{ textAlign: 'center', fontSize: '16px', marginTop: '12px' }}
        >
          You're only{' '}
          <strong>
            <span data-ambassador-next-reward>1</span>
          </strong>{' '}
          referral(s) away from your next reward!
        </Typography>
        <RewardTable>
          {false && (
            <RewardItem
              image="early-access.png"
              referralCount={1}
              value="Exclusive"
              title="Early Access: Summer of SEO Video Course"
              description={
                <>
                  Coming in July! A brand new, 8-week program for developers to
                  learn SEO: <strong>Summer of SEO Challenge</strong>.
                  <br />
                  <br />
                  The challenge features 15, 5-minute videos to get your site
                  from zero to optimized from a technical SEO perspective. 100%
                  Free.
                  <br />
                  <br />
                  Want to get in sooner? For every 1 person you refer, you'll
                  move up the waitlist.
                </>
              }
            />
          )}
          <RewardItem
            image="email-shoutout.png"
            referralCount={2}
            value="Exclusive"
            title="Newsletter Shoutout to Over 7,000 Subscribers"
            description={
              <>
                As a way to say “Thanks!” for your very first referral, you’ll
                get your Name, Twitter Handle, and Blog URL shared at the bottom
                of the next Blogging for Devs Newsletter.
                <br />
                <br />
                Your shoutout will be delivered to over {SUBSCRIBER_COUNT}{' '}
                developers and folks in tech around the world.
              </>
            }
          />
          <RewardItem
            image="masterclass.png"
            referralCount={5}
            value="$99 value"
            title="Free, 2-Part Masterclass Bundle"
            description={
              <>
                Get access to two, premium masterclass sessions straight out of
                our Pro community:{' '}
                <Highlight>On-Site SEO for Developers</Highlight> and{' '}
                <Highlight>Craft Your Content Plan</Highlight>.
                <br />
                <br />
                Learn fundamental on-site SEO techniques and put them into
                practice with a well-crafted content plan. Content Planning
                blueprint also included! (Both videos are captioned)
              </>
            }
          />
          <RewardItem
            image="stickers.png"
            referralCount={10}
            value="Exclusive"
            title="Space and SEO-themed Laptop Sticker Pack"
            description={
              <>
                Get a set of three, stunning Space and SEO-themed stickers for
                your laptop. Exclusively available through the Blogging for Devs
                newsletter referral program!
                <br />
                <br />
                Mailed to your house or office from Germany with a hand-written
                thank you note from Monica ✍
              </>
            }
          />
          <RewardItem
            image="virtual-coffee.png"
            referralCount={50}
            value="Exclusive"
            title="1:1 Virtual Coffee with Monica"
            description={
              <>
                Want to get feedback on your blog, troubleshoot an SEO issue, or
                just brainstorm together about how to grow your site or side
                project?
                <br />
                <br />
                Once you refer 50 people, we’ll sit down for a virtual coffee
                and see what we can do to help you take the next step for
                whatever you're working on!
              </>
            }
          />
        </RewardTable>
        <Wrapper
          style={{ maxWidth: '500px', padding: '16px', textAlign: 'center' }}
        >
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            style={{ marginBottom: '24px' }}
          >
            Haven't done the Blogging for Devs Course yet?
          </Typography>
          <Typography variant="h6" component="p" color="textSecondary">
            <Link to="/" style={{ borderBottom: '1px solid' }}>
              Click here
            </Link>{' '}
            to get the free, 7-day course on blogging and SEO for developers.
          </Typography>
        </Wrapper>
      </div>
    </Layout>
  );
};

export default IndexPage;
